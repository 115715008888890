import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
// import Image from '../../../components/image'
import Img from "gatsby-image";

import {BannerWrap} from './banner.stc'


const Banner = ({ imageBanner }) => {
    const projectHeroQueryData = useStaticQuery(graphql `
        query ProjectHeroDataQuery{
            pagedataJson(id: {eq: "single_project_page_data"}) {
                banner_img {
                    childImageSharp {
                        fluid(maxWidth: 1920, maxHeight: 750, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    `);
    const banngerImg = imageBanner? imageBanner : projectHeroQueryData.pagedataJson.banner_img.childImageSharp.fluid;
    return(
        <BannerWrap>
            <Img fluid={banngerImg} alt="Bannière du projet"/>
        </BannerWrap>
    )
}


export default Banner;